import { lazy } from 'react';

export const OutboundHome = lazy(() =>
  import('../pages/OutboundHome').then(module => ({
    default: module.OutboundHome,
  }))
);

export const NewOutbound = lazy(() =>
  import('../pages/NewOutbound').then(module => ({
    default: module.NewOutbound,
  }))
);

export const OutboundOrderTimeline = lazy(() =>
  import('../pages/OutboundOrderTimeline').then(module => ({
    default: module.OutboundOrderTimeline,
  }))
);

export const OutboundOutOfStockOrders = lazy(() =>
  import('../pages/OutboundOutOfStockOrders').then(module => ({
    default: module.OutboundOutOfStockOrders,
  }))
);

export const OutboundDeletedOrders = lazy(() =>
  import('../pages/OutboundDeletedOrders').then(module => ({
    default: module.OutboundDeletedOrders,
  }))
);

export const OutboundOrdersWithErrors = lazy(() =>
  import('../pages/OutboundOrdersWithErrors').then(module => ({
    default: module.OutboundOrdersWithErrors,
  }))
);
