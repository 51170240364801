import { type ToastContainerProps } from 'react-toastify';

import { type OutboundStatus } from '../context/OutboundDataContext';
import { type OutboundOrderTimelineStatus } from '../context/OutboundOrderTimelineDataContext';
import { type CustomInTransitOutboundOrderStatusTimeline } from '../pages/OutboundOrderTimeline/OrderDetails/LeftColumn/StatusTimelineAccordion/status-illustrations';

export const FIVE_MINUTES = 1000 * 60 * 5;
export const ONE_HOUR = 1000 * 60 * 60 * 1;
export const SIBLINGS_PAGES_SEPARATOR = 1;

export const TRACKING_BASE_URL = process.env.REACT_APP_TRACKING_BASE_URL;

export const MARKETPLACE_LOGO_BASE_URL =
  'https://estoca-public.s3.amazonaws.com/images/integrations';

export const ZPL_IMAGE_BASE_URL =
  'http://api.labelary.com/v1/printers/8dpmm/labels/4x6/0';

export const TOAST_DEFAULT_PROPS: Partial<ToastContainerProps> = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  newestOnTop: true,
  closeOnClick: true,
  pauseOnHover: true,
  pauseOnFocusLoss: true,
  draggable: true,
};

export const DD_MM_AAAA_FORMAT_OPTION: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
};

export const DD_OF_MM_OF_YYY_FORMAT_OPTION: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
};

export const REGULAR_EXPRESSIONS = {
  cpf: /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
  cnpj: /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
  cpfOrCnpj:
    /(^\d{3}\.\d{3}\.\d{3}-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$)/,
  zipCode: /^(\d{5})-(\d{3})$/,
  onlyText: /^[a-zA-Z\s]*$/,
  onlyDigit: /^\d*$/,
  hyphen: /-/g,
  nonDigit: /\D/g,
  comma: /,/g,
  dot: /\./g,
  realPrefix: /R\$/g,
} as const;

export const OUTBOUND_STATUS_TRANSLATIONS: {
  [key in OutboundStatus]: string;
} = {
  canceled: 'Cancelado',
  delivered: 'Entregue',
  dispatched: 'Despachado',
  returned: 'Retornado',
  holded: 'Reservado',
  in_transit: 'Em trânsito',
  packed: 'Empacotado',
  packing: 'Empacotando',
  picked: 'Separado',
  picking: 'Separando',
  waiting_labels: 'Aguardando Liberação',
} as const;

export const OUTBOUND_STATUS_TITLE_TRANSLATIONS: {
  [key in OutboundStatus | 'all']: string;
} = {
  all: 'Todos os pedidos',
  ...OUTBOUND_STATUS_TRANSLATIONS,
} as const;

export const OUTBOUND_ORDER_STATUS_ACCORDION_TITLE_TRANSLATIONS: {
  [key in OutboundOrderTimelineStatus]: string;
} = {
  integrated: 'O pedido foi integrado',
  'out-of-stock': 'Pedido não liberado',
  'has-label-error': 'Pedido não liberado',
  released: 'O pedido foi liberado',
  deleted: 'O pedido foi deletado',
  separating: 'O pedido está sendo separado',
  canceled: 'O pedido foi cancelado',
  separated: 'O pedido foi separado',
  packing: 'O pedido está sendo empacotado',
  packed: 'O pedido foi empacotado',
  dispatched: 'O pedido foi despachado',
  in_transit: 'O pedido está em trânsito',
  delivered: 'O pedido foi entregue',
  returned: 'O pedido retornou ao armazém',
} as const;

export const OUTBOUND_ORDER_STATUS_IN_TRANSIT_ACCORDION_TITLE_TRANSLATIONS: {
  [key in CustomInTransitOutboundOrderStatusTimeline]: string;
} = {
  'delivered-but-in_transit-selected': 'O pedido foi entregue',
  'returned-but-in_transit-selected': 'O pedido retornou ao armazém',
} as const;

export const XML_FILE_TYPE = 'text/xml';

export const INPUT_ACCEPT = {
  pdf: '.pdf, application/pdf',
  photos: 'image/png, image/jpeg',
  xml: '.xml, text/xml',
  csv: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
} as const;

export const INPUT_MASKS = {
  CPF: '000.000.000-00',
  CNPJ: '00.000.000/0000-00',
  CELLPHONE: '(00) 0 0000-0000',
  ZIP_CODE: '00000-000',
} as const;
