import { ErrorComponent } from '@Estoca/ui';
import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';

import { OutboundApp, type OutboundAppProps } from './OutboundApp';

const lifecycles = singleSpaReact<OutboundAppProps>({
  React,
  ReactDOM,
  rootComponent: OutboundApp,
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    return <ErrorComponent error={err} />;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;
