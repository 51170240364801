import { PageLoading } from '@Estoca/ui';
import { Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import {
  NewOutbound,
  OutboundDeletedOrders,
  OutboundHome,
  OutboundOrderTimeline,
  OutboundOrdersWithErrors,
  OutboundOutOfStockOrders,
} from './lazyRoutes';

export const Routes = () => (
  <Suspense fallback={<PageLoading />}>
    <BrowserRouter>
      <Switch>
        <Route exact path="/outbound" component={OutboundHome} />
        <Route exact path="/outbound/new" component={NewOutbound} />

        <Route
          exact
          path="/outbound/order/timeline/:orderId"
          component={OutboundOrderTimeline}
        />

        <Route
          exact
          path="/outbound/out-of-stock"
          component={OutboundOutOfStockOrders}
        />

        <Route
          exact
          path="/outbound/deleted"
          component={OutboundDeletedOrders}
        />

        <Route
          exact
          path="/outbound/errors"
          component={OutboundOrdersWithErrors}
        />
      </Switch>
    </BrowserRouter>
  </Suspense>
);
